import React from 'react';
import { Link } from 'react-router-dom';


const MainWrapper = (props: any) => {
  const { children } = props;
  const year = new Date().getFullYear();

    return (
      <div className="theme-light ltr-support">
        <div className="wrapper">
          {children}
        </div>
          <div className='footer'>
            <b>Copyright © { year } Action Driven Education.</b> | <Link to={'/terms'}>Terms of Use</Link> | <Link to={'/privacy'}>Privacy Policy</Link>
            <div className={'footer-info'}>
            Legal: Action Driven Education is a support guide to assist IEP teams in developing appropriate accommodations and modifications that meet students’ needs. As every child is different, ADE accepts no responsibility for the selection, use or development of any accommodation, modification or goal used or not used in a child’s IEP. This responsibility rests with each child’s IEP team. We are here to support you!
            </div>
          </div>
      </div>
    );

}

export default MainWrapper;
