import {BROWSE_ACCOMODS_CATEGORY, BROWSE_CLEAR_ACCOMODS_CATEGORY} from '../types';
import {apiURL} from "../../services/helpers";
import axios from "axios";
import {accoData, needItemsData} from "../../data/accomods";

export const clearBrowseCategory = () => (dispatch) => {
  dispatch({
    type: BROWSE_CLEAR_ACCOMODS_CATEGORY
  });
}

function newAbortSignal(timeoutMs) {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeoutMs || 0);

  return abortController.signal;
}

// export const getBrowserCategory = ( categoryId ) => async dispatch => {
//
//   try {
//     let accomods = [];
//     const q = "{'Payload.NeedsCategories': { $elemMatch: { '__i.guid': '"+categoryId+"' }}}";
//     const res = await axios.get(apiURL()+'/Accomods?action=get&i=0&size=250&q='+encodeURI(q), { signal: newAbortSignal(5000) });
//
//     /// SORT THE ACCOMODS
//     accomods = [...res.data.r];
//     accomods.sort((a, b) => (parseInt(a.Key.substr(1)) > parseInt(b.Key.substr(1))) ? 1 : -1);
//
//     const q2 = "{ '_id': '"+categoryId+"' }";
//     const res2 = await axios.get(apiURL()+'/NeedItem?action=get&i=0&size=250&q='+encodeURI(q2), { signal: newAbortSignal(5000) });
//
//     dispatch({
//       type: BROWSE_ACCOMODS_CATEGORY,
//       payload: { categoryId: categoryId, accomods: accomods, categoryData: res2.data.r[0] }
//     });
//
//     return 'ok';
//
//   } catch (err) {
//     return 'timeout';
//     console.log('error loading Accomods category');
//   }
//
// }

export const getBrowserCategory = ( categoryId ) => async dispatch => {

  try {
    let accomods = [];

    const res = accoData.filter( (acco) => {
      let hasCat = false;
      acco.NeedsCategories.map((nc) => {
        if (nc.__i.guid === categoryId) { hasCat = true; }
      });
      return hasCat;
    });
    /// SORT THE ACCOMODS
    accomods = res;
    accomods.sort((a, b) => (parseInt(a.Key.substr(1)) > parseInt(b.Key.substr(1))) ? 1 : -1);

    const res2 = needItemsData.filter((ni) => ni.__i.guid === categoryId).pop();

    dispatch({
      type: BROWSE_ACCOMODS_CATEGORY,
      payload: { categoryId: categoryId, accomods: accomods, categoryData: res2 }
    });

    return 'ok';

  } catch (err) {
    return 'timeout';
    console.log('error loading Accomods category');
  }

}
