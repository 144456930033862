import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/actions/alert';
import { registerUser } from '../../redux/actions/auth';
import { Col, Container, Row } from "reactstrap";
import { Navigate } from 'react-router';
import { useForm } from 'react-hook-form';
import PageTitle from '../../components/PageTitle';
import axios from 'axios';
import Topbar from '../Layout/topbar/Topbar';

const RegisterDistrict = (props: any) => {

    const { isAuthenticated } = props;

    const [ numStudents, setNumStudnents ] = useState(0);
    const [ licenseEstimate, setLicenseEstimate ] = useState(0);
    const [ emailState, setEmailState ] = useState('composing');

    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            FirstName: '',
            LastName: '',
            Email: '',
            Phone: '',
            Address1: '',
            Address2: '',
            City: '',
            State: '',
            ZipCode: '',
            SchoolName: '',
        }
    });

    const submitForm = async (values: any) => {
        setEmailState('sending');

        const requestDate = new Date();
        const data = {
            api_key: 'api-CB20EB80BCA411EAB672F23C91C88F4E',
            to: ['Tim Kretchman <timkretchman@actiondriveneducation.com>'],
            sender: values.FirstName + ' ' + values.LastName + ' <' + values.Email + '>',
            subject: 'Accomods: School District Estimate Request',
            text_body:  'A new School District Estimate has been requested:\n\n' +
                        'Requestor Information: \n' +
                        'Name....: '+ values.FirstName + ' ' + values.LastName + "\n" +
                        'Email...: ' + values.Email + "\n" +
                        'Phone...: ' + values.Phone + '\n\n' +
                        'School/District Information: \n' +
                        'School Name......: ' + values.SchoolName + "\n" +
                        'Address 1........: ' + values.Address1 + "\n" +
                        'Address 2........: ' + values.Address2 + "\n" +
                        'City/State/Zip...: ' + values.City + ', ' + values.State + '  ' + values.ZipCode + '\n\n' +
                        'Estimate Information: \n' +
                        'Students.........: ' + numStudents.toLocaleString() + "\n" +
                        'Estimate.........: ' + '$'+licenseEstimate.toLocaleString()+'.00\n' +
                        'Request Date.....: ' + requestDate.toLocaleString() + "\n",

            html_body:  '<h2>A new School District Estimate has been requested:</h2>' +
                        '<h4 style="color:#3E787D">Requestor Information:</h4>' +
                        '<table>' +
                        '<tr><td width="135"><b>Name</b></td><td>'+ values.FirstName + ' ' + values.LastName + '</td></tr>' +
                        '<tr><td width="135"><b>Email</b></td><td>'+ values.Email + '</td></tr>' +
                        '<tr><td width="135"><b>Phone</b></td><td>'+ values.Phone + '</td></tr>' +
                        '</table>' +
                        '<h4 style="color:#3E787D">School/District Information:</h4>' +
                        '<table>' +
                        '<tr><td width="135"><b>School Name</b></td><td>'+ values.SchoolName + '</td></tr>' +
                        '<tr><td width="135"><b>Address 1</b></td><td>'+ values.Address1 + '</td></tr>' +
                        '<tr><td width="135"><b>Address 2</b></td><td>'+ values.Address2 + '</td></tr>' +
                        '<tr><td width="135"><b>City/State/Zip</b></td><td>'+ values.City + ', ' + values.State + '  ' + values.ZipCode + '</td></tr>' +
                        '<tr><td width="135"><b>Phone</b></td><td>'+ values.Phone + '</td></tr>' +
                        '</table>' +
                        '<h4 style="color:#3E787D">Estimate Information:</h4>' +
                        '<table>' +
                        '<tr><td width="135"><b>Request Date</b></td><td>'+ requestDate.toLocaleString() + '</td></tr>' +
                        '<tr><td width="135"><b>Students</b></td><td>'+ numStudents.toLocaleString() + '</td></tr>' +
                        '<tr><td width="135"><b>Estimate</b></td><td>'+ '$'+licenseEstimate.toLocaleString()+'.00' + '</td></tr>' +
                        '</table>',

            custom_headers: [
                {
                    header: 'Reply-To',
                    value: values.FirstName + ' ' + values.LastName + ' <' + values.Email + '>',
                }
            ]
        };

        try {
            const sendEmail = await axios.post('https://api.smtp2go.com/v3/email/send', data);
            if (sendEmail && sendEmail.status && sendEmail.status === 200) {
                setEmailState('sent');
                //console.log('emailSend:', sendEmail);
            }
        } catch(err) {
            setEmailState('error');
        }

    };

    const updateStudents = ( e:any ) => {
        const val = parseInt(e.target.value) || 0;
        const price = val === 0 ? 0 : Math.floor(600 + ( val / 5000 * 500 ) + ( val * 0.2 ));
        setNumStudnents( val );
        setLicenseEstimate( price );
    }

    // Redirect if already signed in
    if (isAuthenticated) {
        return <Navigate to="/dashboard"/>
    }

    return (
        <>
            <Topbar />

            <div className={'acco-register'}>

                <PageTitle title='Get an Estimate'
                           subtitle={'Pricing based on the number of students in your school or school district.'}/>

                <form className="auth-form" onSubmit={handleSubmit(submitForm)}>
                    <Container>
                        <Row>
                            <Col md={6}>
                                <h4 className="form-set-header">Account Information</h4>
                                <div className="form-set">
                                    <div className={`form-group`}>
                                        <label className={'req'}>{errors.FirstName && <span>Required</span>}Your First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("FirstName", { required: true, minLength: 1, maxLength: 64 })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label className={'req'}>{errors.LastName && <span>Required</span>}Your Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("LastName",{ required: true, minLength: 1, maxLength: 64 })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label className={'req'}>{errors.Email && <span>Required</span>}Email Address</label>
                                        <input
                                            type="text"
                                            placeholder="jdoe@example.com"
                                            className={`form-control`}
                                            {...register("Email",{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label className={'req'}>{errors.Phone && <span>Required</span>}Phone Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="321-555-1212"
                                            {...register("Phone", { required: true, minLength: 1, maxLength: 64 })}
                                        />
                                    </div>

                                </div>

                            </Col>
                            <Col md={6}>
                                <h4 className="form-set-header">School / District Information</h4>
                                <div className="form-set">
                                    <div className={`form-group`}>
                                        <label className={'req'}>{errors.SchoolName && <span>Required</span>}School or District Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("SchoolName", { required: true, minLength: 1, maxLength: 128 })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label className={'req'}>{errors.Address1 && <span>Required</span>}Address 1</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("Address1", { required: true, minLength: 1, maxLength: 128 })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label>Address 2</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("Address2")}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <label className={'req'}>{errors.City && <span>Required</span>}City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register("City", { required: true, minLength: 1, maxLength: 128 })}
                                        />
                                    </div>
                                    <div className={`form-group`}>
                                        <div className="form-group-half">
                                            <label className={'req'}>{errors.State && <span>Required</span>}State</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("State", { required: true, minLength: 1, maxLength: 128 })}
                                            />
                                        </div>
                                        <div className="form-group-half">
                                            <label className={'req'}>{errors.ZipCode && <span>Required</span>}Zip Code</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                {...register("ZipCode", { required: true, minLength: 1, maxLength: 5 })}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <br/><br/>
                        <Row>
                            <Col md={6} className="text-center">
                                <div className="form-group">
                                    <label>Number of Students in School / District</label>
                                    <input
                                        name="Students"
                                        type="number"
                                        min={0}
                                        max={1000000}
                                        value={numStudents}
                                        onChange={e => updateStudents(e)}
                                        className="form-control est-req"
                                    />
                                </div>
                            </Col>
                            <Col md={6} className="text-center">
                                <div className={`form-group`}>
                                    <label>Estimated Annual Licensing Cost</label>
                                    <input
                                        name="Estimate"
                                        type="text"
                                        value={'$'+licenseEstimate.toLocaleString()+'.00'}
                                        onChange={e => {}}
                                        className="form-control est-req"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{size: 6, offset: 3}} className="text-center">
                                <br/><br/>
                                <div className={(emailState === 'sending' ? 'disabled' : emailState === 'sent' ? 'hidden' : '')}>
                                    <p>By clicking the REQUEST ESTIMATE button, you are requesting that an Accomods representative contact you regarding pricing for an Accomods account for your school or school district.<br/>&nbsp;</p>
                                    <button type={`submit`} className={`btn btn-primary btn-pill btn-lg`} disabled={(emailState==='sending')} >Request Estimate</button>
                                </div>
                                <div className={`estimateSent ` + (emailState==='sent' ? 'sent' : '')}>
                                    <div className='alert alert-success'>
                                        <b>Estimate Request Sent!</b>
                                        <br/>Your request for an estimate has been recieved. An Accomods representative will be in contact with you soon.
                                    </div>
                                </div>
                                <div className={`estimateSendError ` + (emailState==='error' ? 'showError' : '')}>
                                    <div className='alert alert-danger'>
                                        <b>Error!</b>
                                        <br/>There was a problem sending your estimate request.<br/>Please try again or call 1-888-ACCOMOD for assistance.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </form>

            </div>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {setAlert, registerUser})(RegisterDistrict);
