import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from '../containers/Login/Login';
import MainWrapper from '../components/MainWrapper';
import { removeAlert } from '../redux/actions/alert';
import { connect } from 'react-redux';
import RegisterAccount from '../containers/Register/RegisterAccount';
import RegisterIndividual from '../containers/Register/RegisterIndividual';
import RegisterCode from '../containers/Register/RegisterCode';
import { getCurrentProfile } from '../redux/actions/profile';
import RegisterDistrict from '../containers/Register/RegisterDistrict';
import Privacy from '../containers/Misc/Privacy';
import Terms from '../containers/Misc/Terms';
import RegisterComplete from '../containers/Register/RegisterComplete';
import Dashboard from '../containers/Dashboard/Dashboard';
import BuilderStrengths from '../containers/Builder/BuilderStrengths';
import BuilderNeeds from '../containers/Builder/BuilderNeeds';
import BuilderPrioritize from '../containers/Builder/BuilderPrioritize';
import BuilderSelect from '../containers/Builder/BuilderSelect';
import BuilderExport from '../containers/Builder/BuilderExport';
import BrowserTopics from '../containers/Browser/BrowserTopics';
import Search from '../containers/Search/Search';
import AccoCart from '../containers/Cart/AccoCart';
import MyAccount from '../containers/MyProfile/MyProfile';
import Layout from '../containers/Layout';
import AccoModal from '../components/AccoModal';

import GoToLogin from './GoToLogin';
import { logout, setStoredLocalData } from '../redux/actions/auth';
import { setAuthToken } from '../utils/setAuthToken';
import useScrollToTop from '../hooks/useScrollToTop';

const Router = (props: any) => {

    const { auth, profile, modal, getCurrentProfile, logout, setStoredLocalData } = props;

    const [readyToGo, setReadyToGo] = useState( -1 );

    const location = useLocation();
    const navigate = useNavigate();

    const pathname = location.pathname;

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    useScrollToTop();

    useEffect(() => {
        if (!!auth.userName && !!auth.userId && !!auth.access_token && profile.loading) {
            (async () => {
                //console.log('getting profile stuff')
                await setStoredLocalData();
                const gotProfile = await getCurrentProfile(auth.userName);
                setReadyToGo( !!gotProfile ? 1 : 0 );
            })();
        } if (!!auth.userName && !!auth.userId && !!auth.access_token && !profile.loading) {
            //console.log('got the profile!');
            setReadyToGo( 1 );
        } else {
            //console.log('login no bueno')
            setReadyToGo( 0 );
        }
    }, [auth.userName, auth.userId, auth.access_token, profile.loading])


    useEffect(() => {
        if (pathname === '/' && readyToGo === 1) { navigate('/dashboard'); }
    },[pathname, readyToGo])
    //
    // console.log(userName, userId, token);
    // console.log('ready2go', readyToGo);


    return (
        <MainWrapper>
            <main>
                { readyToGo === 1 ?
                    <>
                        <div className={'mainBody' + (modal.open ? ' acco-modal-blur' : '')} id="appRoot">
                            <Layout userData={profile}/>
                            <div className="container__wrap">
                                <Routes>
                                    <Route path="/dashboard" element={<Dashboard/>}/>
                                    <Route path="/builder/strengths" element={<BuilderStrengths/>}/>
                                    <Route path="/builder/needs" element={<BuilderNeeds/>}/>
                                    <Route path="/builder/prioritize" element={<BuilderPrioritize/>}/>
                                    <Route path="/builder/select" element={<BuilderSelect/>}/>
                                    <Route path="/builder/export" element={<BuilderExport/>}/>
                                    <Route path="/browser/topics" element={<BrowserTopics/>}/>
                                    <Route path="/search" element={<Search/>}/>
                                    <Route path="/plan" element={<AccoCart/>}/>
                                    <Route path="/my-profile" element={<MyAccount userProfile={profile}/>}/>
                                    <Route path="/" element={<Dashboard/>}/>
                                </Routes>
                            </div>
                        </div>
                        <AccoModal/>
                    </>
                : readyToGo === 0 ?
                    <Routes>
                        <Route path="/" element={<Login/>} />
                        <Route path="/register/individual" element={<RegisterIndividual/>} />
                        <Route path="/register/district" element={<RegisterDistrict/>} />
                        <Route path="/register/code" element={<RegisterCode/>} />
                        <Route path="/register/complete" element={<RegisterComplete/>} />
                        <Route path="/register" element={<RegisterAccount/>} />
                        <Route path="/privacy" element={<Privacy/>} />
                        <Route path="/terms" element={<Terms/>} />
                        <Route path="/:any" element={<GoToLogin/>} />
                        <Route path="/:any/:any" element={<GoToLogin/>} />
                        <Route path="/:any/:any/:any" element={<GoToLogin/>} />
                        {/*<ScrollToTop/>*/}
                    </Routes>
                : <div>Loading...</div>
                }
            </main>
        </MainWrapper>
    );
}


const mapStateToProps = (state: any) => ({
    auth: state.auth,
    profile: state.profile,
    modal: state.modal
});

export default connect(mapStateToProps, { getCurrentProfile, setStoredLocalData, removeAlert, logout })(Router);


