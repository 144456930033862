import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PageTitle from '../../components/PageTitle';
import BrowserNav from './BrowserNav';
import BrowserTopicDetails from './BrowserTopicDetails';
import { clearBrowseCategory, getBrowserCategory } from '../../redux/actions/browser';
import kids2 from '../../shared/img/kids2.png';
import { connect } from 'react-redux';
import gears from '../../shared/img/gears.gif';

const BrowserTopics = (props: any) => {

    const { data, browser, getBrowserCategory, clearBrowseCategory } = props;

    const needsLoaded = data && data.needs && data.needs.length;
    const needs = needsLoaded ? data.needs : [];
    const docsLoaded = browser && browser.categoryData && browser.categoryData.Name && browser.accomods && browser.accomods.length;

    const [docsLoading, setDocsLoading] = useState( false );
    const [docsTimeout, setDocsTimeout] = useState( false );
    const [showTopicDetail, setShowTopicDetails] = useState( false );

    const topicClick = async ( topicId: string ) => {
        if (topicId !== '0') {
            setDocsLoading( true );
            setDocsTimeout( false );
            //console.log( topicId );
            setShowTopicDetails( true );
            let res = await getBrowserCategory(topicId);
            setDocsLoading( false );

            if (res === 'timeout') {
                setDocsTimeout( true );
            }
        }
    };

    return (
        <>
        <PageTitle title='Browse Accommodations' builder={true} browse={false} search={true} subtitle={'We\'ve organized our database of accommodations and modifications by learning needs to help you quickly narrow down those that suit your student\'s needs.'} />
        <Container>
            <Row>
                <Col sm={12}>
                    <div className='acco-browser'>
                        <div className='acco-browser-select'>
                            <select className='form-control' onChange={(e:any)=>topicClick(e.target.value)}>
                                <option value='0'>Please select a category</option>
                                <option disabled key='selector-space' ></option>
                                { needs.map( (cat:any, i:number) =>
                                    <>
                                    <option disabled key={`cat`+i} >{ cat.Name }</option>
                                    { cat.Needs.map( (n:any) => <option value={n.__i.guid} key={n.__i.guid}>{ n.Name }</option> ) }
                                    <option disabled key={`space`+i} ></option>
                                    </>
                                )}
                            </select>
                        </div>
                        <div className='acco-browser-nav'>
                            { needs.map( (cat:any) => <BrowserNav category={cat} topicClick={topicClick} key={cat.__i.guid} /> ) }
                            <img src={kids2} alt="students" className="" />
                        </div>
                        <div className='acco-browser-body'>
                            { docsTimeout ?
                                <div className="alert alert-danger text-center">
                                    &nbsp;<br/><b>Timeout error:</b>
                                    <br/>Unfortunately, your request to browse this topic timed out. Please try again.<br/>&nbsp;
                                </div>
                            : docsLoading ?
                                <div className="gears"><img src={gears} alt="loading gears" className="" /></div>
                            : docsLoaded ?
                                <BrowserTopicDetails></BrowserTopicDetails>
                            :
                                <div className="alert alert-success text-center">
                                    &nbsp;<br/>To begin, select a topic from the left navigation menu.<br/>&nbsp;
                                </div>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    data: state.data,
    browser: state.browser
});

export default connect(mapStateToProps, { getBrowserCategory, clearBrowseCategory })(BrowserTopics);
